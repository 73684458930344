.nav-container {
  display: flex;
  justify-content: flex-end;
}

.nav-element {
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
  color: #9f78a9;
}
.nav-element:hover {
  color: #13dfba;
}

@media (max-width: 860px) {
  .nav-container {
    display: none;
  }
}
