/* light pinkish: #fffcfc; */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #1e0325;
}

p {
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  font-family: "Allan", sans-serif;
  font-size: 4rem;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  line-height: 0.9;
}

h3 {
  font-family: "Allan", sans-serif;
  font-size: 1.5rem;
}

h4 {
  font-family: "Raleway", sans-serif;
  font-size: 2.3rem;
}

h5 {
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
  margin: 0.5rem 0 0.5rem 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

section {
}
