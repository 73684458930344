.experience-description-timecompany {
  display: flex;
}

.experience-description-timecompany p {
  font-size: 0.9rem;
  padding: 0 0.5rem 0 0;
  margin: 0;
}

.experience-description-tasks {
  padding: 0 0 0 2rem;
  margin-bottom: 0;
}

.experience-list {
  margin: 0;
}

.experience-listel {
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.3;
}
