* {
  box-sizing: border-box;
}
.line {
  border: none;
  height: 1px;
  background-color: #9f78a9;
  margin: 3rem 0;
}
/* .fading {
  animation: fade-in 2.5s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.goup-container {
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}
