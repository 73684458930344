button {
  width: fit-content;

  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
  border: none;
  font-size: 1rem;
}

.dark {
  background-color: #1e0325;
  color: white;
}

.dark:hover {
  background-color: #13dfba;
}

button:focus {
  border: none;
  outline: none;
}

.light {
  background-color: #9f78a9;
  color: white;
}

.light:hover {
  background-color: #1e0325;
}
