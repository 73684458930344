.skills-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 22rem;
}

.skills-element {
  display: flex;
}

.skills-element_rating {
  font-size: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
}

@media (max-width: 1000px) {
  .skills-container {
    height: 40rem;
  }
}

@media (max-width: 800px) {
  .skills-container {
    height: 52rem;
  }
}

@media (max-width: 600px) {
  .skills-container {
    height: auto;
  }
}
