.hero-container {
  display: flex;
}

.hero-container div {
  flex: 1;
}

.hero-container h1 {
  margin: 0;
}

.hero-container h4 {
  margin: 0;
}

.hero-description-text {
  padding: 1rem 0;
}

.hero-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 860px) {
  .hero-container {
    flex-direction: column;
    padding-top: 3rem;
  }
}
