.aboutMe-container {
  display: flex;
}

.aboutMe-text {
  flex: 1;
  text-align: justify;
}

.aboutMe-links {
  flex: 1;
}

.aboutMe-links div {
  display: flex;
  flex-direction: column;
  padding-left: 40%;
}
.aboutMe-links div img {
  width: 7rem;
}

.aboutMe-links ul {
  padding: 0;
}

.aboutMe-links ul li {
  padding: 1rem 0 1rem 0;
  cursor: pointer;
}

@media (max-width: 650px) {
  .aboutMe-container {
    flex-direction: column;
  }
  .aboutMe-links div {
    flex-direction: row;
    padding-left: 1rem;
  }
  .aboutMe-links ul {
    padding-left: 25%;
  }
}
